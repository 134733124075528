<template>
  <div>
    <VCard>
      <VCardTitle>
        <VRow align="center" justify="space-between">
          <VCol cols="auto">
            {{ $t("paymentPlan.payment_plans") }}
          </VCol>
          <VCol cols="auto">
            <VBtn
              v-if="!permissions.can_only_see_plans"
              depressed
              class="success-bg text-white text-transform-none br-10"
              dark
              @click="onClickCreate"
            >
              <VIcon :left="!$vuetify.breakpoint.xs">mdi-plus</VIcon>
              <div v-if="!$vuetify.breakpoint.xs">
                {{ $t("paymentPlan.new_plan") }}
              </div>
            </VBtn>
          </VCol>
        </VRow>
      </VCardTitle>
    </VCard>
    <VCard class="my-2 py-1 px-1">
      <VRow align="center" justify="space-between" no-gutters>
        <VCol cols="12" xl="3" md="3" sm="12">
          <VTextField
            prepend-inner-icon="mdi-magnify"
            dense
            :label="$t('form.search')"
            v-model="searchString"
            @input="onSearchProduct"
            hide-details="true"
            clearable
            ref="searchField"
            solo
            :loading="isLoadingSearch"
          />
        </VCol>
        <VCol cols="auto" v-if="!$vuetify.breakpoint.smAndDown">
          <v-btn-toggle v-model="listView" @change="onCahngeListView" mandatory dense>
            <VBtn>
              <VIcon color="#757575">mdi-menu</VIcon>
            </VBtn>
            <VBtn>
              <card-list-icon />
            </VBtn>
          </v-btn-toggle>
        </VCol>
      </VRow>
    </VCard>
    <VSlideXTransition v-if="listView === 1" mode="out-in">
      <plans-list-cards
        @onCLickCard="onCLickCard"
        :paymentPlansList="paymentPlansList"
        :defaultPlan="defaultPlan"
        :activeCardID="activeCardID"
        :loadingList="loadingList"
      />
    </VSlideXTransition>
    <VSlideXReverseTransition v-if="listView === 0 && !$vuetify.breakpoint.smAndDown">
      <plans-list-table
        @onCLickCard="onCLickCard"
        :paymentPlansList="paymentPlansList"
        :defaultPlan="defaultPlan"
        :activeCardID="activeCardID"
        :loadingList="loadingList"
      />
    </VSlideXReverseTransition>

    <VRow
      align="center"
      justify="center"
      style="height:20vh"
      v-if="paymentPlansList.length === 0 && !loadingList"
    >
      Список тарифних планів порожній
    </VRow>
    <PaymentPlansDialog
      :visible="visibleDialog"
      @close="visibleDialog = false"
      :uuid="InfoPlanUUID"
      :type="dialogType"
      v-if="visibleDialog"
      :defaultPlan="defaultPlan"
    />
  </div>
</template>

<script>
import language from "../../mixins/language";
import user from "../../mixins/user";
import notifications from "../../mixins/notifications";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import loader from "../../mixins/loader";
import PaymentPlansDialog from "./PaymentPlansDialog.vue";
import EventBus from "../../events/EventBus";
import PlansListCards from "./PlansListCards.vue";
import PlansListTable from "./PlansListTable.vue";

export default {
  components: { PaymentPlansDialog, PlansListCards, PlansListTable },
  data: () => ({
    visibleDialog: false,
    InfoPlanUUID: "",
    paymentPlansList: [],
    dialogType: "",
    defaultPlan: "",
    activeCardID: "",
    loadingList: true,
    listView: "",
    searchString: "",
    isLoadingSearch: false
  }),
  mixins: [language, notifications, loader, user],
  beforeCreate() {},
  mounted() {
    this.checkViewList();
    this.getDefaultPlan();
    EventBus.$on("payment-plan-modified", data => {
      this.paymentPlansList = [];
      this.visibleDialog = false;
      this.getPlansList();
      this.getDefaultPlan();
    });
    EventBus.$on("payment-plan-created", data => {
      this.paymentPlansList = [];
      this.visibleDialog = false;
      this.searchString = "";
      this.getPlansList();
      this.getDefaultPlan();
    });
    this.getPlansList();
  },
  watch: {
    paymentPlansList: {
      deep: true,
      handler() {
        if (this.$vuetify.breakpoint.smAndDown) {
          this.listView = 1;
        }
      }
    },
    getListView: {
      deep: true,
      handler(e) {}
    },
    $vuetify: {
      deep: true,
      handler(e) {
        if (e.breakpoint.smAndDown) {
          console.log(e);
          this.listView = 1;
        } else {
          this.checkViewList();
        }
      }
    }
  },
  methods: {
    onSearchProduct() {
      const params = {
        uselike: true,
        name: this.searchString
      };
      this.getPlansList(params, true);
    },
    async getDefaultPlan() {
      try {
        const defaultPlan = await paymentPlansService.usersPlansDefault();
        this.defaultPlan = defaultPlan.uuid;
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    async getPlansList(params = {}, isSearchLoading = false) {
      try {
        if (isSearchLoading) {
          this.isLoadingSearch = true;
        } else {
          this.setLoading(true);
        }
        this.loadingList = true;
        params.orderby = "time_created";
        params.order = "DESC";
        const plansItem = await paymentPlansService.usersPlansList(params);
        this.paymentPlansList = plansItem;
        if (isSearchLoading) {
          this.isLoadingSearch = false;
        } else {
          this.setLoading(false);
        }
        this.loadingList = false;
        //  this.isLoadingSearch = false
      } catch (e) {
        this.loadingList = false;
        if (isSearchLoading) {
          this.isLoadingSearch = false;
        } else {
          this.setLoading(false);
        }
        this.setErrorNotification(e);
      }
    },
    onCLickCard(e) {
      this.activeCardID = e;
      this.InfoPlanUUID = e;
      this.dialogType = "edit";
      this.visibleDialog = true;
    },
    onClickCreate() {
      this.dialogType = "create";
      this.visibleDialog = true;
    },
    onCahngeListView(e) {
      if (e === 0) {
        window.localStorage.setItem("listView", 0);
      } else {
        window.localStorage.setItem("listView", 1);
      }
    },
    checkViewList() {
      const localListView = window.localStorage.getItem("listView");
      if (localListView) {
        this.listView = localListView == 0 ? 0 : 1;
      }
    }
  }
};
</script>

<style></style>
