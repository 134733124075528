import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import EventBus from "../../events/EventBus";

export default {
  mixins: [notifications, language],
  methods: {
    async getPaymentPlan() {
      try {
        this.loadingCard = true;
        this.linearQuery = 20;
        const planItem = await paymentPlansService.usersPlansInfo(this.uuid);
        setTimeout(() => {
          this.linearQuery = 100;
        }, 200);
        this.dataInsertion(planItem);
        this.inputPlan = { ...this.plan };
        this.checkIsDefault();
        this.loadingCard = false;
      } catch (e) {
        this.loadingCard = false;
        this.setErrorNotification(e);
      }
    },
    checkIsDefault() {
      if (this.plan.uuid) {
        if (this.defaultPlan === this.plan.uuid) {
          this.defaultQuery = true;
        } else {
          this.defaultQuery = false;
        }
      }
    },
    dataInsertion(item) {
      this.plan = {
        name: item.name,
        description: item.description,
        price_delivery: item.price_delivery,
        price_hour_meter: item.price_hour_meter,
        time_created: item.time_created,
        uuid: item.uuid,
        uuid_user_created: item.uuid_user_created,
        is_default: item.is_default
      };
    },

    async onChangeDefault() {
      try {
        this.loadingCard = true;
        if (this.defaultQuery) {
          await paymentPlansService.usersPlansSetDefault(this.plan.uuid);
          this.loadingCard = false;
          EventBus.$emit("payment-plan-modified");
        }
      } catch (e) {
        this.loadingCard = false;
        this.setErrorNotification(e);
      }
    }
  }
};
