<template>
  <VDialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    :persistent="changed"
    @click:outside="showConfirmDialog"
  >
    <VCard>
      <VFadeTransition>
        <v-progress-linear
          v-model="linearQuery"
          :buffer-value="100"
          v-if="loadingCard"
          striped
          color="success"
        ></v-progress-linear>
      </VFadeTransition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loadingCard">
          <VBtn fab small color="white" elevation="0" @click="showConfirmDialog">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </div>
      </v-slide-x-transition>
      <VCardTitle>
        {{ `${getDialogTitle} ${ $t("paymentPlan.plan") }` }}
      </VCardTitle>

      <VCardText class="pb-3">
        <div class="card-text__wrapper">
          <div v-if="!loadingCard">
            <VRow>
              <VCol
                :cols="type === 'edit' && !$vuetify.breakpoint.xs ? 6 : 12"
                :xl="type === 'edit' && !$vuetify.breakpoint.xs ? 6 : 12"
                :md="type === 'edit' && !$vuetify.breakpoint.xs ? 6 : 12"
                sm="12"
              >
                <VTextField
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('form.name')"
                  v-model="plan.name"
                  hide-details="auto"
                  :error-messages="nameErrors"
                  :readonly="permissions.can_only_see_plans"
                />
              </VCol>
              <VCol cols="auto" xl="6" md="6" sm="auto" v-if="type === 'edit'" class="mx-auto">
                <v-checkbox
                  class="ml-1"
                  v-model="defaultQuery"
                  :label="$t('paymentPlan.by_default')"
                  color="success"
                  hide-details
                  :readonly="defaultQuery"
                  @change="onChangeDefault"
                  :disabled="permissions.can_only_see_plans"
                ></v-checkbox>
              </VCol>
            </VRow>
            <VRow>
              <VCol>
                <VTextField
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('paymentPlan.payment_plans_delivery_price')"
                  v-model="plan.price_delivery"
                  :error-messages="priceDeliveryErrors"
                  hide-details="auto"
                  type="number"
                  :readonly="permissions.can_only_see_plans"
                  v-on:keypress="onlyNumber($event)"
                />
              </VCol>
              <VCol>
                <VTextField
                  outlined
                  dense
                  color="success"
                  class="br-10"
                  :label="$t('paymentPlan.payment_plans_hours_price')"
                  v-model="plan.price_hour_meter"
                  hide-details="auto"
                  type="number"
                  :error-messages="priceHoursErrors"
                  :readonly="permissions.can_only_see_plans"
                  v-on:keypress="onlyNumber($event)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12" class="pb-0">
                <VTextarea
                  outlined
                  dense
                  hide-details="auto"
                  class="br-10 "
                  color="success"
                  :rows="8"
                  :label="$t('form.description')"
                  counter="512"
                  maxLength="512"
                  v-model="plan.description"
                  :readonly="permissions.can_only_see_plans"
                />
              </VCol>
            </VRow>
          </div>
        </div>
        <VFadeTransition>
          <VSheet class="loader__wrapper" v-if="loadingCard">
            <div class="loader__inner">
              <v-progress-circular indeterminate color="success"></v-progress-circular>
            </div>
          </VSheet>
        </VFadeTransition>
      </VCardText>
      <VCardActions>
        <VRow no-gutters justify="space-between">
          <VCol cols="6" class="pr-1">
            <VBtn dark block class="br-10 text-transform-none" @click="onCancel">{{
              $t("btn.cancel")
            }}</VBtn>
          </VCol>
          <VCol cols="6" class="pl-1">
            <VBtn
              block
              v-if="type === 'edit' && !permissions.manager"
              class="success-bg text-white text-transform-none br-10"
              @click="onUpdate"
              :disabled="loadingCard || !changed"
              >{{ $t("btn.update") }}</VBtn
            >
            <VBtn
              block
              v-else-if="type === 'create'"
              class="success-bg text-white text-transform-none br-10"
              @click="onCreate"
              :disabled="loadingCard || !changed"
              >{{ $t("btn.create") }}</VBtn
            >
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
  </VDialog>
</template>

<script>
import _ from "lodash";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import PaymentDialogMixin from "./PaymentDialogMixin";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import EventBus from "../../events/EventBus";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";

export default {
  mixins: [language, notifications, PaymentDialogMixin, validationMixin, user],
  components: { ConfirmLeftDialog },
  props: {
    visible: {
      require: true
    },
    uuid: {
      require: false
    },
    type: {
      require: true
    },
    defaultPlan: {
      require: false
    }
  },
  validations: {
    plan: {
      name: { required },
      price_delivery: { required },
      price_hour_meter: { required }
    }
  },
  data: () => ({
    plan: {
      name: "",
      description: "",
      price_delivery: "",
      price_hour_meter: "",
      is_default: "",
      uuid_user_created: "",
      uuid: "",
      time_created: ""
    },
    loadingCard: true,
    linearQuery: 10,
    changed: false,
    inputPlan: {},
    visibleConfirm: false,
    defaultQuery: false
  }),
  mounted() {
    if (this.type === "edit") {
      this.getPaymentPlan();
    }
    if (this.type === "create") {
      this.loadingCard = false;
      this.inputPlan = { ...this.plan };
    }
  },
  watch: {
    plan: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.plan, this.inputPlan);
      }
    }
  },
  methods: {
    onlyNumber(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/[0-9]$/.test(char)) return true;
      e.preventDefault();
    },
    onCancel() {
      this.$emit("close");
      this.$v.$reset();
      this.plan = {};
    },
    showConfirmDialog() {
      if (this.changed) {
        this.visibleConfirm = true;
      } else {
        this.onCancel();
      }
    },
    async onUpdate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loadingCard = true;
          this.linearQuery = 20;
          const formData = new FormData();
          formData.append("uuid", this.plan.uuid);

          const changedData = this.$getChangedData(this.plan, this.inputPlan);
          changedData.forEach(data => {
            formData.append(data.field, data.value);
          });

          await paymentPlansService.usersPlansUpdate(formData);
          setTimeout(() => {
            this.linearQuery = 100;
          }, 200);
          EventBus.$emit("payment-plan-modified");
          this.$v.$reset();
          this.loadingCard = false;
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loadingCard = true;
          this.linearQuery = 20;
          const formData = new FormData();
          formData.append("name", this.plan.name);
          if (this.plan.description) {
            formData.append("description", this.plan.description);
          }
          formData.append("price_delivery", this.plan.price_delivery);
          formData.append("price_hour_meter", this.plan.price_hour_meter);
          await paymentPlansService.usersPlansAdd(formData);
          setTimeout(() => {
            this.linearQuery = 100;
          }, 200);
          EventBus.$emit("payment-plan-created");
          this.$v.$reset();
          this.loadingCard = false;
        } catch (e) {
          this.setErrorNotification(e);
        }
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.plan.name.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.plan.name.required && errors.push(this.$t('form.errors.NameRequired'));
      return errors;
    },
    priceDeliveryErrors() {
      const errors = [];
      if (!this.$v.plan.price_delivery.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.plan.price_delivery.required &&
        errors.push((this.$t('form.errors.paymentPlansDeliveryPriceRequired')));
      return errors;
    },
    priceHoursErrors() {
      const errors = [];
      if (!this.$v.plan.price_hour_meter.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.plan.price_hour_meter.required &&
        errors.push((this.$t('form.errors.paymentPlanHoursPriceRequired')))
      return errors;
    },
    getDialogTitle() {
      if (this.type === "create") {
        return this.$t("paymentPlan.creatingTitle");
      } else if (this.type === "edit" && !this.permissions.can_only_see_plans) {
        return this.$t("paymentPlan.updaingitle");
      } else if (this.permissions.can_only_see_plans) {
        return this.$t("paymentPlan.seingTitle");
      } else {
        return "--";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card-text__wrapper {
  position: relative;
  min-height: 244px;
}
.loader {
  &__inner {
    width: 100%;
    height: 260px;
    background: white;
    z-index: 200;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 58px;
    left: 0;
  }
}
</style>
