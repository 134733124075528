<template>
    <VRow no-gutters align="center" justify="start" v-if="paymentPlansList.length > 0 && !loadingList "  >
         <VCol
        cols="12"
        xl="4"
        lg="4"
        md="6"
        sm="12"
        
        v-for="item in paymentPlansList"
        :key="item.uuid"
        class="px-3"
        :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''"
      >
        <ns-badge ns-color="info" :ns-content="$t('paymentPlan.by_default')" v-if="defaultPlan === item.uuid && permissions.can_view_default_plan" ns-size="sm">
          <VCard
            :min-width="$vuetify.breakpoint.xs ? 270 : 320"
            class="my-2"
            @click="onCLickCard(item.uuid)"
            :ripple="{ class: 'success--text' }"
            :color="activeCardID === item.uuid ? '#F9FFE3' : ''"
          >
            <VCardTitle
              ><VRow aling="center" justify="space-between">
                <VCol class="text-truncate">{{ item.name || "--" }}</VCol>
                <VCol cols="auto"><VIcon>mdi-information-outline</VIcon></VCol>
              </VRow></VCardTitle
            >
            <VCardText>
              <VRow>
                <VSimpleTable class="my-table plans w-100">
                  <tbody>
                    <tr>
                      <th>{{ $t('paymentPlan.payment_plans_delivery_price') }}</th>
                      <th class="green--text">{{ item.price_delivery || "--" }} {{$t('paymentPlan.uah')}}</th>
                    </tr>
                    <tr>
                      <th>{{$t('paymentPlan.payment_plans_hours_price') }}</th>
                      <th class="green--text">{{ item.price_hour_meter || "--" }} {{$t('paymentPlan.uah')}}</th>
                    </tr>
                  </tbody>
                </VSimpleTable>
              </VRow>
            </VCardText>
          </VCard>
        </ns-badge>
        <VCard v-else
          :min-width="$vuetify.breakpoint.xs ? '270' : 320"
          class="my-2"
          @click="onCLickCard(item.uuid)"
          :ripple="{ class: 'success--text' }"
           :color="activeCardID === item.uuid ? '#F9FFE3' : ''"
        >
         <v-tooltip bottom v-if="item.is_active && item.is_active == '1'">
            <template v-slot:activator="{ on, attrs }">
              <VBtn right class="badge" absolute icon dark x-small v-on="on" v-bind="attrs"><VIcon small>mdi-star</VIcon></VBtn>
            </template>
            {{$t('paymentPlan.active_plan')}}
         </v-tooltip>
          <VCardTitle
            ><VRow aling="center" justify="space-between">
              <VCol class="elispsis" style="max-width:300px">{{ item.name || "--" }}</VCol>
              <VCol cols="auto"><VIcon>mdi-information-outline</VIcon></VCol>
            </VRow></VCardTitle
          >
          <VCardText>
            <VRow>
              <VSimpleTable class="my-table plans w-100">
                <tbody>
                  <tr>
                    <th>{{ $t('paymentPlan.payment_plans_delivery_price') }}</th>
                    <th class="green--text">{{ item.price_delivery || "--" }} {{$t('paymentPlan.uah')}}</th>
                  </tr>
                  <tr>
                    <th>{{ $t('paymentPlan.payment_plans_hours_price')}}</th>
                    <th class="green--text">{{ item.price_hour_meter || "--" }} {{$t('paymentPlan.uah')}}</th>
                  </tr>
                </tbody>
              </VSimpleTable>
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
  </VRow>
</template>

<script>
import language from "../../mixins/language";
import user from '@/mixins/user'

export default {

mixins:[language,user],
props:['paymentPlansList','defaultPlan','activeCardID' ,'loadingList'],
methods:{
    onCLickCard(e){
        this.$emit('onCLickCard',e)
    }
}
}
</script>

<style scoped>
.badge {
  right: 0;
  top: -8px;
  background-color: #FBC02D
}
</style>