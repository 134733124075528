<template>
  <VRow
    no-gutters
    align="center"
    justify="space-between"
    v-if="paymentPlansList.length > 0 && !loadingList"
  >
    <VCol cols="12" :class="$vuetify.breakpoint.smAndDown ? 'mx-auto' : ''">
      <VCard class="mb-1 pt-2 pb-1">
        <VSimpleTable class="my-table payment-row w-100 pa-2" :dense="!isLarge">
          <thead>
            <tr>
              <th class="text-center" v-if="!permissions.can_only_see_plans"><VIcon dense>mdi-cogs</VIcon></th>
              <th class="lg">{{ $t('table.name') }}</th>
              <th>{{ $t('paymentPlan.payment_plans_delivery_price') }}</th>
              <th>{{ $t('paymentPlan.payment_plans_hours_price') }}</th>
              <th>{{ $t('table.description') }}</th>
            </tr>
          </thead>
          <tbody >
            <tr
              v-for="item in paymentPlansList"
              :key="item.uuid"
              :class="{ 'active-row': activeCardID == item.uuid}"
            >
              <th class="icon text-center" v-if="!permissions.can_only_see_plans">
                <VBtn
                  color="primary"
                  small
                  class="btn-edit"
                  @click="onClickBtn(item.uuid)"
                  :class="{ sm: !isLarge }"
                >
                  <VIcon dense v-if="!permissions.manager">mdi-pencil</VIcon>
                  <VIcon dense v-else>mdi-file-search</VIcon>
                </VBtn>
              </th>
              <th>
                <VRow align="center" justify="space-between" no-gutters>
                {{ item.name || "--" }}
                <VIcon v-if="defaultPlan === item.uuid && permissions.can_view_default_plan" color="blue darken-3" outlined dense>
                    mdi-star
                </VIcon> 
                <v-tooltip bottom v-if="item.is_active && item.is_active == '1'">
                  <template v-slot:activator="{ on, attrs }"> 
                    <VIcon v-on="on" v-bind="attrs" color="yellow darken-2" outlined dense>
                        mdi-star
                    </VIcon>  
                 </template>
                 {{$t('paymentPlan.active_plan')}}
                </v-tooltip>
                </VRow>
              </th>
              <th class="green--text">{{ item.price_delivery || "--" }} {{$t('paymentPlan.uah')}}</th>
              <th class="green--text">{{ item.price_hour_meter || "--" }}  {{$t('paymentPlan.uah')}}</th>
              <th class="lg">{{ item.description || "--" }}</th>
            </tr>
          </tbody>
        </VSimpleTable>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import language from "../../mixins/language";
import user from "../../mixins/user";
import SizeUi from "../../mixins/SizeUi";

export default {
  mixins: [language, SizeUi,user],
  props: ["paymentPlansList", "defaultPlan", "activeCardID", "loadingList"],
  methods: {
    onClickBtn(e) {
      this.$emit("onCLickCard", e);
    }
  }
};
</script>
